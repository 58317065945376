const CustomInputField = ({
  register,
  identifer,
  type = "text",
  placeholder,
}) => {
  return (
    <div className="w-fit">
      <label
        class="block text-gray-700 text-sm font-bold mb-2 px-2"
        for={identifer}
      >
        {placeholder}
      </label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="username"
        type={type}
        placeholder={placeholder}
        {...register(identifer, {})}
      />
    </div>
  );
};

export default CustomInputField;
