import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import Text from "../../../Shared/Text";
import { Call, ExpandMore } from "@mui/icons-material";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../Config/BaseUrl";
import EditCustomerDetails from "./EditCustomerDetails";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Income and Expense",
    },
  },
};

const CustomerOverview = ({ data1, storeId, setRefetchDetails }) => {
  const [graph, setGraph] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    const GetGraphData = () => {
      axios
        .get(
          `${baseURL}/customer-pannel/customer-payment-record-graph-api-in-bhaaraterp/?store_id=${storeId}`,
          {
            headers: {
              authorization: localStorage.getItem("customersToken"),
            },
          }
        )
        .then((response) => {
          setGraph(response?.data?.data);
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });

    };

    storeId && GetGraphData();
  }, [storeId]);

  const labels = graph?.map((item) => item?.month);

  const data = {
    labels,
    datasets: [
      {
        label: "Totalamount",
        data: graph?.map((item) => item?.total_amount),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Totalpaid",
        data: graph?.map((item) => item?.total_paid),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <>
      <Box className="flex flex-col lg:w-1/3 lg:!text-xl !text-sm ">
        <Text className="text-xl font-bold text-center lg:py-2">BharatErp</Text>
        <Divider />
        <span className="items-center justify-between gap-3 p-2 my-3 lg:flex lg:items-start">
          <div className="flex flex-row gap-20 md:gap-5">
            <Avatar
              className="!rounded"
              src="https://contacts.zoho.in/file?t=user&ID=60018272715&fs=thumb"
              alt=""
            />
            <span className="flex flex-col items-center justify-center ">
              <Text className="!text-sm md:!text-lg">
                {data1?.first_name}
                {data1?.middle_name}
                {data1?.last_name}
              </Text>
              <Text>{data1?.email}</Text>
              <Text>
                <Call fontSize="10" /> {data1?.mobile_no}
              </Text>
            </span>
          </div>
          <div className="flex items-center h-full">
            <Button
              disableElevation
              variant="contained"
              size="small"
              className="!h-10"
              onClick={() => setIsEditModalOpen(true)}
            >
              Edit Details
            </Button>
          </div>
          {isEditModalOpen && (
            <EditCustomerDetails
              isEditModalOpen={isEditModalOpen}
              setIsEditModalOpen={setIsEditModalOpen}
              customerDetails={data1}
              storeId={storeId}
              refetch={setRefetchDetails}
            />
          )}
        </span>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Address</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Text className=" !text-sm lg:!text-lg">Billing Address :{data?.address1}
            </Text>
            <Text className=" !text-sm lg:!text-lg">Area: {data1?.area}</Text>
            <Text className=" !text-sm lg:!text-lg">City:{data1?.city}</Text>
            <Text className=" !text-sm lg:!text-lg">State:{data1?.state}</Text>
            <Text className=" !text-sm lg:!text-lg">Pincode:{data1?.pincode}</Text>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Other Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <span className="flex flex-col gap-2  !text-sm lg:!text-lg">
              <Text className=" !text-sm lg:!text-lg">Customer Type : {data1?.customer_type} </Text>
              <Text className=" !text-sm lg:!text-lg">Default Currency : {data1?.currency} </Text>
              <Text className=" !text-sm lg:!text-lg">Payment Terms : {data1?.payment_terms} </Text>
              <Text className=" !text-sm lg:!text-lg">Website : {data1?.website} </Text>

              <Text>PAN : {data1?.pan_number} </Text>
            </span>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Record Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Text className=" !text-sm lg:!text-lg">Customer ID : {data1?.cutomer_code}</Text>
            <Text className=" !text-sm lg:!text-lg">Created on : {data1?.created_at}</Text>
            <Text className=" !text-sm lg:!text-lg">Created By : {data1?.created_by}</Text>
          </AccordionDetails>
        </Accordion>
      </Box>
      <div className="flex flex-col w-[100%] h-96 lg:w-2/3  lg:gap-3 lg:p-2 lg:px-5 ">
        <Bar options={options} data={data} height={"100%"} />
      </div>
    </>
  );
};

export default CustomerOverview;
