import { Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Helmet } from "react-helmet";
import { baseURL } from "../../Config/BaseUrl";
import { useQuery } from "react-query";
import { postFn } from "../../Services";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [key, setKey] = useState([]);
  const [otp, setOtp] = useState("");
  const [checked, setChecked] = useState(true);
  const [email1, setEmail1] = useState([]);
  const [loder, setLoder] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const SendOto = (event) => {
    event.preventDefault();
    axios
      .post(`${baseURL}/customer-pannel/get-customer-login-otp-api/`, {
        email_or_mobile: email,
      })
      .then((response) => {
        setKey(response.data.otp_key);
        setEmail1(response.data.email);
        response.data.response_code === 200 && setChecked(false);
        enqueueSnackbar(response.data.message, {
          autoHideDuration: 2000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        setEmail("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useQuery(
    ["verifyUser"],
    () => postFn({}, "/customer-pannel/verify-customer-token-api/"),
    {
      onSuccess: (res) => {
        if (res.data.response_code === 200) {
          enqueueSnackbar(res.data.message, {
            autoHideDuration: 2000,
            variant: "success",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          });
          navigate("/home");
        }
      },
      onError: () => {
        localStorage.removeItem("customersToken");
      },
      retry: 0,
      enabled: Boolean(localStorage.getItem("customersToken")),
    }
  );

  const handleLogin = (event) => {
    setLoder(true);
    event.preventDefault();
    const fd = new FormData();
    fd.append(`${key}`, otp);
    fd.append("email", email1);
    axios
      .post(`${baseURL}/customer-pannel/customer-login-api/`, fd, {})
      .then((response) => {
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("customersToken", response.data.token);
        localStorage.setItem("customer_name", response.data.customer_name);
        enqueueSnackbar(response.data.message, {
          autoHideDuration: 2000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        response.data.response_code === 200 && navigate("/home");
        setLoder(false);
      });
  };

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Customers Login Bharat Erp Panel</title>
          <link rel="canonical" href="https://bharaterp.org" />
        </Helmet>
      </div>
      <CustomDiv
        className="!rounded-none h-screen w-screen center justify-center items-start"
        elevation={0}
      >
        <CustomDiv
          className="!rounded !lg:bg-gray-200 lg:h-3/4 lg:w-2/3 lg:flex lg:px-0 px-10"
          elevation={0}
        >
          <CustomDiv
            elevation={0}
            className="center lg:!block !hidden !rounded-none lg:w-1/2 h-full py-10 "
          >
            <img
              src="https://aaraerp.s3.ap-south-1.amazonaws.com/static/logos/Group-481461.png"
              alt=""
              className="lg:!block !hidden"
            />
          </CustomDiv>
          <CustomDiv
            elevation={0}
            className="lg:w-1/2  !rounded-none center flex-col gap-4 my-5 lg:my-0"
          >
            <div className="px-10 lg:px-0">
              <img
                src="https://bhaaraterp.s3.ap-south-1.amazonaws.com/logobhaarat.svg"
                alt=""
                className="mb-5 lg:h-10 lg:w-40"
              />
            </div>
            <Text className="text-2xl border-b-2 cursor-pointer">
              <span className="font-semibold text-center text-blue-500">
                Bharat Erp{" "}
              </span>
              Customers Panel
            </Text>
            <Text className="mt-5 text-5xl font-semibold lg:text-3xl">
              Sign In
            </Text>

            {checked ? (
              <>
                <form onSubmit={SendOto}>
                  <TextField
                    label="Email/Number"
                    type="text"
                    id="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    className="w-[100%] py-5"
                  />
                  {email ? (
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      className="!rounded w-[100%] !capitalize !my-5"
                    >
                      Send
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      className="!rounded w-[100%] !capitalize !my-5"
                      disabled
                    >
                      Send
                    </Button>
                  )}
                </form>
              </>
            ) : (
              <>
                <form onSubmit={handleLogin}>
                  <TextField
                    label="Otp"
                    type="text"
                    // id="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="!w-[100%]"
                  />
                  {otp ? (
                    <>
                      {loder ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <Button
                          variant="contained"
                          size="large"
                          type="submit"
                          className="!rounded w-[100%] !capitalize !my-5"
                        >
                          Sign In
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      className="!rounded w-[100%] !capitalize !my-5"
                      disabled
                    >
                      Sign In
                    </Button>
                  )}
                </form>
              </>
            )}
            <Text>
              Don't you have any acount?
              <Link
                className="pl-2 text-blue-400 underline"
                onClick={() => setChecked(true)}
              >
                Resend
              </Link>
            </Text>
          </CustomDiv>
        </CustomDiv>
      </CustomDiv>
    </>
  );
};

export default SignIn;
