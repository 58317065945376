import axiosInstance from "../Config/BaseUrl";

export const getFn = (reqBody, url) => {
  try {
    const response = axiosInstance.get(url, {
      params: reqBody,
    });
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};

export const postFn = (reqBody, url) => {
  try {
    const response = axiosInstance.post(url, reqBody);
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};

export const deleteFn = (reqBody, url) => {
  try {
    const response = axiosInstance.delete(url, {
      data: reqBody,
    });
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};

export const putFn = (reqBody, url) => {
  try {
    const response = axiosInstance.put(url, reqBody);
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};
