import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "../Authentication/SignIn";
import Layout from "../Layout";
import EstimatesPdf from "../Pages/EstimatesPdf";
import InvoicesPdf from "../Pages/InvoicesPdf";
const AppRoutes = ({ theme, setTheme }) => {
  const routes = [
    {
      path: "/home",
      element: <Layout setTheme={setTheme} theme={theme} />,
    },
    {
      path: "/estimates-pdf/:pdf/:storeId",
      element: <EstimatesPdf />,
    },
    {
      path: "/invoices-pdf/:pdf/:storeId",
      element: <InvoicesPdf />,
    },
  ];

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
