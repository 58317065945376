import { useEffect, useMemo, useState } from "react";
import { Button, Tab, Tabs } from "@mui/material";
import CustomModal from "../../../Shared/CustomModal";
import { TabContext, TabPanel } from "@mui/lab";
import { useForm } from "react-hook-form";
import CustomInputField from "../../../Shared/FormInputs/CustomInputField";
import axiosInstance from "../../../Config/BaseUrl";
import CustomSelectField from "../../../Shared/FormInputs/CustomSelectField";
import AddressSelect from "./AddressSelect";
import { useSnackbar } from "notistack";

const EditCustomerDetails = ({
  isEditModalOpen,
  setIsEditModalOpen,
  customerDetails,
  storeId,
  refetch,
}) => {
  const [value, setValue] = useState("0");
  const [listing, setListing] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit } = useForm({
    defaultValues: customerDetails,
    resetOptions: {
      keepDirtyValues: false,
    },
  });
  const onSubmit = (data) => {
    console.log(storeId);
    const reqBody = {
      ...data,
      store_id: storeId,
    };
    const updateCustomerData = async () => {
      try {
        const response = await axiosInstance.put(
          `/customer-pannel/customer-details-api-in-bhaaraterp/?store_id=${storeId}`,
          reqBody
        );
        enqueueSnackbar(response?.data?.message, {
          autoHideDuration: 2000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        refetch((prev) => prev + 1);
        setIsEditModalOpen(false);
        return response;
      } catch ({ error }) {
        throw new Error(error.message);
      }
    };

    updateCustomerData(data);
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const addCustomersListingFn = async () => {
      try {
        const response = await axiosInstance.get(
          `/customer-pannel/customer-data-api/`
        );
        setListing(response);
        return;
      } catch ({ error }) {
        throw new Error(error.message);
      }
    };

    addCustomersListingFn();
  }, []);

  const customerTypes = useMemo(
    () => listing?.data?.data?.customer_type,
    [listing]
  );

  const firmStatus = useMemo(() => listing?.data?.data?.firm_status, [listing]);

  const gstCategory = useMemo(
    () => listing?.data?.data?.gst_category,
    [listing]
  );

  return (
    <>
      <CustomModal
        title="Edit Details"
        className="lg:w-[60%] w-[80%]"
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col p-4 bg-white"
        >
          <TabContext value={value}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
            >
              <Tab
                label="Basic details"
                className={`!capitalize !text-black`}
                value="0"
              />
              <Tab
                label="Address"
                className={`!capitalize !text-black`}
                value="1"
              />
              <Tab
                label="Other Details"
                className={`!capitalize !text-black`}
                value="2"
              />
            </Tabs>
            <TabPanel value="0">
              <div className="flex !flex-col flex-wrap gap-4 lg:!flex-row">
                <CustomInputField
                  register={register}
                  identifer="first_name"
                  placeholder="First Name"
                />
                <CustomInputField
                  register={register}
                  identifer="last_name"
                  placeholder="Last Name"
                />
                <CustomInputField
                  register={register}
                  identifer="mobile_no"
                  placeholder="Mobile"
                  type="number"
                />
                <CustomInputField
                  register={register}
                  identifer="email"
                  placeholder="Email"
                  type="email"
                />
              </div>
            </TabPanel>
            <TabPanel value="1">
              <div className="flex lg:!flex-row  flex-col flex-wrap gap-4">
                <AddressSelect
                  register={register}
                  customerData={customerDetails}
                />
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div className="grid lg:!grid-cols-2 lg:gap-4 gap-2 lg:!text-lg !text-sm">
                <CustomInputField
                  register={register}
                  identifer="company_name"
                  placeholder="Company Name"
                  type="text"
                />
                <CustomSelectField
                  register={register}
                  identifer="customer_type"
                  placeholder="Customer Type"
                  options={customerTypes}
                />
                <CustomSelectField
                  register={register}
                  identifer="firm_status"
                  placeholder="Firm Status"
                  options={firmStatus}
                />
                <CustomSelectField
                  register={register}
                  identifer="gst_category"
                  placeholder="GST Category"
                  options={gstCategory}
                />
                <CustomInputField
                  register={register}
                  identifer="pan_number"
                  placeholder="Pan Number"
                  type="text"
                />
              </div>
            </TabPanel>
          </TabContext>
          <Button
            disableElevation
            variant="outlined"
            size="small"
            className="!w-24 !mx-auto !mt-5 !text-black !border-black"
            type="submit"
          >
            Apply
          </Button>
        </form>
      </CustomModal>
    </>
  );
};

export default EditCustomerDetails;
