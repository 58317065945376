import { React, useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import pdf from "../../Assets/pdf.png";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import noRecordEstimates from "../../Assets/noRecordEstimates.svg";
import Text from "../../Shared/Text";
import axios from "axios";
import { Helmet } from "react-helmet";
import { baseURL } from "../../Config/BaseUrl";

function createData(name, calories, fat, carbs, protein, newRow, pdf) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    newRow,
    pdf,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "DATE",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "ESTIMATE NUMBER",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "REFERENCE NUMBER",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "CUSTOMER NAME",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "newRow",
    numeric: true,
    disablePadding: false,
    label: "AMOUNT",
  },
  {
    id: "pfd",
    numeric: true,
    disablePadding: false,
    label: "PDF",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Customers Estimates BhaaratErp Panel</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </div>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function Estimates() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [data, setData] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [rows,setRows]=useState({})
  const [customerData, setCustomerData] = useState([]);
  const [store, setStore] = useState([]);
  const [storeId, setStoreId] = useState();
  const navegate = useNavigate();

  useEffect(() => {
    const GetStore = () => {
      axios
        .get(
          `${baseURL}/customer-pannel/customer-store-list-api-in-bhaaraterp/`,
          {
            headers: {
              authorization: localStorage.getItem("customersToken"),
            },
          }
        )
        .then((response) => {
          setStore(response?.data?.store_list);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    GetStore();
  }, []);

  useEffect(() => {
    if (store && !storeId) {
      setStoreId(store[0]?.store_id);
    }
  }, [store, storeId]);

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            All Estimates
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Filter">
              <div className="flex justify-between gap-10 px-5">
                <IconButton>
                  <FilterList />
                </IconButton>
              </div>
            </Tooltip>
          </>
        )}
      </Toolbar>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const rows = customerData?.map((i) =>
    createData(
      i?.date,
      i?.estimate_no,
      i?.reference_no,
      i?.customer?.first_name + " " + i?.customer?.last_name,
      i.customer_estimate_status,
      i?.total_amount,
      i?.id
    )
  );

  useEffect(() => {
    const getData = () => {
      axios
        .get(
          `${baseURL}/customer-pannel/customer-estimate-api-in-bhaaraterp/?page=1&store_id=${storeId}`,
          {
            headers: {
              authorization: localStorage.getItem("customersToken"),
            },
          }
        )
        .then((response) => {
          setCustomerData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    storeId && getData();
  }, [storeId]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  return data ? (
    <div className="center flex-col gap-2 md:!text-lg !text-sm h-[60vh]">
      <img src={noRecordEstimates} alt="" />
      <Text className="text-lg">No Estimates Record Found</Text>
    </div>
  ) : (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected?.length} />
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Store</InputLabel>
          <Select
            value={storeId || ""}
            onChange={(event) => setStoreId(event.target.value)}
            autoWidth
            label="Age"
            className="!h-10"
          >
            {store?.map((item) => {
              return (
                <MenuItem key={item?.store_id} value={item?.store_id}>
                  {item?.store_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row?.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row?.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row?.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell align="right">{row?.calories}</TableCell>
                      <TableCell align="right">{row?.fat}</TableCell>
                      <TableCell align="right">{row?.carbs}</TableCell>
                      <TableCell align="right">{row?.protein}</TableCell>
                      <TableCell align="right">{row?.newRow}</TableCell>

                      <TableCell align="right">
                        {" "}
                        <img
                          onClick={() =>
                            navegate(`/estimates-pdf/${row?.pdf}/${storeId}`)
                          }
                          align="right"
                          src={pdf}
                          alt=""
                          className="flex items-start h-7 w-7"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
