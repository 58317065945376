import { useEffect, useState } from "react";
import axiosInstance from "../../../Config/BaseUrl";
import CustomInputField from "../../../Shared/FormInputs/CustomInputField";
import CustomSelectField from "../../../Shared/FormInputs/CustomSelectField";

const AddressSelect = ({ register, customerData }) => {
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(
    customerData.country_id
  );
  const [selectedStateId, setSelectedStateId] = useState(customerData.state_id);
  const [selectedCityId, setSelectedCityId] = useState(customerData.city_id);

  useEffect(() => {
    if (countryList.length === 0) {
      const countryStateCityFn = async () => {
        try {
          const response = await axiosInstance.get(
            "country-state-city-list-api-without-of-bhaaraterp/"
          );

          const countryArr = response?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          setCountryList(countryArr);
        } catch (error) {
          console.error("Error fetching country data:", error);
        }
      };

      countryStateCityFn();
    }
  }, [countryList]);

  useEffect(() => {
    if (selectedCountryId) {
      const countryStateCityFn = async () => {
        try {
          const response = await axiosInstance.get(
            "country-state-city-list-api-without-of-bhaaraterp/",
            {
              params: { country_id: selectedCountryId },
            }
          );

          const statesArr = response?.data?.data?.map((item) => ({
            label: item.state,
            value: item.id,
          }));

          setStateList(statesArr);
        } catch (error) {
          console.error("Error fetching country data:", error);
        }
      };

      countryStateCityFn();
    }
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectedStateId && selectedCountryId) {
      const countryStateCityFn = async () => {
        try {
          const response = await axiosInstance.get(
            "country-state-city-list-api-without-of-bhaaraterp/",
            {
              params: {
                country_id: selectedCountryId,
                state_id: selectedStateId,
              },
            }
          );

          const cityArr = response?.data?.data?.map((item) => ({
            label: item.city,
            value: item.id,
          }));

          setCityList(cityArr);
        } catch (error) {
          console.error("Error fetching country data:", error);
        }
      };

      countryStateCityFn();
    }
  }, [selectedCountryId, selectedStateId]);

  return (
    <div className="grid grid-cols-2 gap-4">
      <CustomInputField
        register={register}
        placeholder="Area"
        identifer="area"
      />
      <CustomSelectField
        register={register}
        placeholder="Country"
        identifer="country_id"
        options={countryList}
        uncontrolled={false}
        value={selectedCountryId}
        setValue={setSelectedCountryId}
      />
      <CustomSelectField
        register={register}
        placeholder="State"
        identifer="state_id"
        options={stateList}
        uncontrolled={false}
        value={selectedStateId}
        setValue={setSelectedStateId}
      />
      <CustomSelectField
        register={register}
        placeholder="City"
        identifer="city_id"
        options={cityList}
        uncontrolled={false}
        value={selectedCityId}
        setValue={setSelectedCityId}
      />
      <CustomInputField
        register={register}
        placeholder="Pin Code"
        identifer="pincode"
      />
    </div>
  );
};

export default AddressSelect;
