import { Paper } from "@mui/material";
import classNames from "classnames";
import React from "react";

const CustomDiv = ({ className = "", elevation, onClick, children }) => {
  return (
    <Paper
      className={classNames("rounded-none", className)}
      elevation={elevation}
      onClick={onClick}
      children={children}
    />
  );
};

export default CustomDiv;
