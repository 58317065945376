import { createTheme, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRoutes from "./Routes";

const App = () => {
  const [theme, setTheme] = useState(
    Boolean(localStorage.getItem("theme") === "true") || false
  );
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
        retry: 1,
      },
    },
  });

  const mode = createTheme({
    palette: {
      mode: theme ? "dark" : "light",
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={mode}>
        <SnackbarProvider maxSnack={2}>
          <AppRoutes theme={theme} setTheme={setTheme} />
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
