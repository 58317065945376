import React from "react";
import CustomDiv from "../Shared/CustomDiv";
import Header from "../Shared/Header";

const Layout = ({ component, setTheme, theme }) => {
  return (
    <CustomDiv
      elevation={0}
      className="h-screen overflow-y-auto w-full !rounded-none"
    >
      <Header setTheme={setTheme} theme={theme} />
      {/* <CustomDiv elevation={0} className="h-body !rounded-none">
        {component}
      </CustomDiv> */}
    </CustomDiv>
  );
};

export default Layout;
