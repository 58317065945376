const CustomSelectField = ({
  register,
  options,
  placeholder,
  identifer,
  uncontrolled = true,
  value = undefined,
  setValue,
}) => {
  return (
    <div className="w-full">
      <label
        class="block px-2 text-gray-700 text-sm font-bold mb-2"
        for={identifer}
        htmlFor={identifer}
      >
        {placeholder}
      </label>
      <select
        className="w-full px-3 py-2 rounded-md cursor-pointer bg-slate-200"
        {...register(identifer)}
        value={value}
        onChange={
          !uncontrolled
            ? (e) => {
                console.log(e.target.value);
                setValue(e.target.value);
              }
            : undefined
        }
      >
        {uncontrolled
          ? options?.map((option, idx) => (
              <option className="hover:bg-gray-200" key={idx} value={option}>
                {option}
              </option>
            ))
          : options?.map((option, idx) => (
              <option
                className="hover:bg-gray-200"
                key={idx}
                value={option?.value}
              >
                {option?.label}
              </option>
            ))}
      </select>
    </div>
  );
};

export default CustomSelectField;
